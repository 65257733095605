import React, { useEffect } from 'react';

import Layout from '@common/Layout';
import { Container } from '@components/global';
import { navigate } from 'gatsby-link';

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
  // return (
  //   <Layout>
  //     <Container>
  //       <h1>NOT FOUND</h1>
  //       <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  //     </Container>
  //   </Layout>
  // );
};

export default NotFoundPage;
